import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import Text from "./Text"

const Row = styled.section`
  position: relative;

  background-color: ${({ backgroundColor }) => backgroundColor};

  padding: calc(${props => props.theme.space.paddingMobile} * 2) 0;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    padding: ${props => props.theme.space.paddingDesktop} 0;
  }
`

const Inner = styled.div`
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    max-width: ${props => props.theme.space.maxContentWidthDesktop};
  }
`

const TextWrapper = styled.div`
  width: 100%;

  margin: 0 auto ${props => props.theme.mobileVW(50)} auto;
  padding: 0 ${props => props.theme.space.paddingMobile};

  text-align: center;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    max-width: ${props => props.theme.desktopVW(800)};

    margin: 0 auto ${props => props.theme.desktopVW(150)} auto;
    padding: 0;
  }
`

const List = styled.div`
  display: none;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${props => props.theme.desktopVW(50)};
    align-items: flex-start;

    width: 100%;
  }
`

const Carousel = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    display: none;
  }
`

const ThumbnailItem = styled.div`
  text-align: center;

  h6 {
    font-family: ${props => props.theme.fontFamily.circularBook};
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    display: block;

    text-align: left;
  }
`

const Image = styled(Img)`
  width: 100%;
  height: ${props => props.theme.mobileVW(250)};

  margin-bottom: ${props => props.theme.mobileVW(15)};

  img {
    opacity: 1 !important;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    height: ${props => props.theme.desktopVW(350)};

    margin-bottom: ${props => props.theme.desktopVW(20)};
  }
`

const RelatedItems = ({
  data,
  className,
  prefix,
  title,
  backgroundColor: { color },
}) => {
  const params = {
    spaceBetween: 30,
    slidesPerView: 1.5,
    preloadImages: false,
    centeredSlides: true,
    loop: true,
    grabCursor: true,
  }

  return (
    <Row className={className} backgroundColor={color}>
      <Inner>
        <TextWrapper>
          <ScrollAnimation animateIn="fadeInUp" duration={1.5}>
            <Text data={title} />
          </ScrollAnimation>
        </TextWrapper>
        <List>
          {data.edges.map((item, index) => {
            return (
              <ThumbnailItem key={index}>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  delay={index * 150}
                  duration={1.5}
                >
                  <Link to={`/${prefix}/${item.node.slug}`}>
                    <Image
                      objectFit="cover"
                      fluid={item.node.thumbnail.fluid}
                      alt={item.node.name}
                    />
                    <h6>{item.node.name}</h6>
                  </Link>
                </ScrollAnimation>
              </ThumbnailItem>
            )
          })}
        </List>
        <Carousel>
          <ScrollAnimation animateIn="fadeInUp" duration={1.5}>
            <Swiper {...params}>
              {data.edges.map((item, index) => {
                return (
                  <ThumbnailItem key={index}>
                    <Link to={`/${prefix}/${item.node.slug}`}>
                      <Image
                        objectFit="cover"
                        fluid={item.node.thumbnail.fluid}
                        alt={item.node.name}
                      />
                      <h6>{item.node.name}</h6>
                    </Link>
                  </ThumbnailItem>
                )
              })}
            </Swiper>
          </ScrollAnimation>
        </Carousel>
      </Inner>
    </Row>
  )
}

export default RelatedItems
